// import App from 'next/app'
import "@styles/styles.scss"
import { ThemeProvider } from "@emotion/react"
import type { AppProps } from "next/app"
import { SSRProvider } from "react-bootstrap"

const theme = {
  colors: {
    primary: "var(--bs-primary)",
    secondary: "var(--bs-secondary)",
    success: "var(--bs-success)",
    danger: "var(--bs-danger)",
    light: "var(--bs-light)",
    dark: "var(--bs-dark)",
    warning: "var(--bs-warning)",
    greybright: "var(--grey-bright)",
  },
}

export default function MyApp({ Component, pageProps }: AppProps) {
  return (
    <SSRProvider>
      <ThemeProvider theme={theme}>
        <Component {...pageProps} />
      </ThemeProvider>
    </SSRProvider>
  )
}
